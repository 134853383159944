
import { getLastDiaryMealList } from '@/api/diaryMeal';
import { Component, Vue } from 'vue-property-decorator';
import Pagination from '@/components/Pagination/index.vue';
import FoodRecentForm from './foodRecentForm.vue';

@Component({
  components: {
    FoodRecentForm,
    Pagination,
  },
})
export default class extends Vue {
  mounted() {
    this.getLastDiaryMealList();
  }

  private loading = true;

  private listQuery = {
    page: 0,
    size: 10,
  }

  private totalElements = 0;

  private historyList: any = [];

  private selectedIdx: any = null;

  private selectedItem: any = null;

  private formVisible = false;

  private getLastDiaryMealList() {
    this.loading = true;
    this.selectedIdx = null;
    this.selectedItem = null;

    getLastDiaryMealList(this.listQuery).then((res) => {
      this.loading = false;
      this.historyList = res.data.content;
      this.totalElements = res.data.totalElements;
    });
  }

  /* eslint-disable */
  private handleSubmit() {
    if (this.selectedIdx == null) {
      this.$message.warning('등록할 최근 기록을 선택해주세요.');
      return false;
    }
    this.selectedItem = this.historyList[this.selectedIdx];
    this.formVisible = !this.formVisible;
  }

  private handleCloseForm() {
    this.selectedIdx = null;
    this.selectedItem = null;
    this.formVisible = false;
  }

  private handleSuccessForm() {
    this.handleCloseForm();
    this.$router.push({ name: 'Diary', query: { ...this.$route.query } });
    // this.getLastDiaryMealList();
  }
}
