
import { Component, Prop, Vue } from 'vue-property-decorator';
import { addDiaryMealByHistory } from '@/api/diary';

@Component({
})
export default class extends Vue {
  @Prop({ required: true }) private meal!: any;

  mounted() {
    this.form = {
      weight: this.meal.amount,
      mealIdx: this.meal.idx,
      type: this.$route.query.type,
      diaryDate: this.$route.query.diaryDate,
    };
  }

  private form = {
    weight: 0,
    mealIdx: this.meal.idx,
    type: this.$route.query.type,
    diaryDate: this.$route.query.diaryDate,
  }

  /* eslint-disable */
  private valueFromWeight(val: any) {
    if (!val || val === 0) return 0;
    const v1 = val / this.meal.amount;
    return (v1 * this.form.weight).toFixed(2);
  }
  /* eslint-enable */

  private handleAddDiaryMeal() {
    this.form = {
      ...this.form,
      mealIdx: this.meal.idx,
      type: this.$route.query.type,
      diaryDate: this.$route.query.diaryDate,
    };
    addDiaryMealByHistory(this.form).then(() => {
      this.$message.success('다이어리에 추가되었습니다.');
      this.$emit('success');
    });
  }
}
